<template>
<div>
  <iframe :src="$route.query.target" width="100%" :height="$vuetify.breakpoint.height-74" style="border: none;" >

  </iframe>
</div>
</template>

<script>
export default {
  name: "ExternalAppView"
}
</script>

<style scoped>

</style>